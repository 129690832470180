import { render, staticRenderFns } from "./default.vue?vue&type=template&id=d0676b86"
import script from "./default.vue?vue&type=script&lang=js"
export * from "./default.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {JbHeader: require('/buddy/advantis-frontend/node_modules/jbcomponents/components/Jb/Header.vue').default,JbLoginModal: require('/buddy/advantis-frontend/node_modules/jbcomponents/components/Jb/LoginModal.vue').default})
